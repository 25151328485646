import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import MainHome from './MainHome';
import Main2 from './Main2';

// This array holds information about different videos


function App() {


  return (
    <>
      <Router>
        <Routes>



          {/* <Route exact path="/watch=6040424" Component={Home} /> */}


          <Route exact path="/malafakan/video/9190924" Component={MainHome} />


          <Route exact path="/malafakan/video/7180924" Component={Main2} />
          {/* <Route exact path="/watch=6270624" Component={MainHome} /> */}
          {/* <Route exact path="/watch=2040624" Component={Home3} /> */}
          {/* <Route exact path="/watch=4300124" Component={Template2} /> */}


        </Routes>
      </Router>


      {/* <MainHome /> */}
    </>
  );

}

export default App;
